import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule, Location } from '@angular/common';

@Component({
    selector: 'es-unauthorized-page',
    templateUrl: './unauthorized-page.component.html',
    styleUrls: ['./unauthorized-page.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule],
})
export class UnauthorizedPageComponent {
    constructor(
        private activatedRoute: ActivatedRoute,
        private location: Location
    ) {
        const replaceUrl = this.activatedRoute.snapshot.queryParams['url'];
        if (replaceUrl) {
            this.location.replaceState(replaceUrl);
        }
    }
}
